import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/charts/styles.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { GoogleOAuthProvider } from "@react-oauth/google";
//import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReduxProvider from "./redux/ReduxProvider";
import { PostHogProvider } from "posthog-js/react"


const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST as string,
}



export const queryClient = new QueryClient();



const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <BrowserRouter>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY as string}
      options={options}
    >
      <ReduxProvider>
        <QueryClientProvider client={queryClient}>
          <MantineProvider>
            <Notifications />
            <GoogleOAuthProvider
              clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
            >
              <App />
              {/*<ReactQueryDevtools />*/}
            </GoogleOAuthProvider>
          </MantineProvider>
        </QueryClientProvider>
      </ReduxProvider>
    </PostHogProvider>
  </BrowserRouter>,
);
