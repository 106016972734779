import axios from "../api/index";
import React from "react";
import { countries } from "country-flag-icons";


export default function useGeolocation() {
  const [ipAddress, setIpAddress] = React.useState<string | null>("");
  const [country, setCountry] = React.useState<any>(null);
  const [countryFlag, setCountryFlag] = React.useState<any | null>();
  React.useEffect(() => {
    (async function fetchUserIpAddress() {
      try {
        const { data } = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(data.ip);
      } catch (error) {
        console.error("Error fetching user IP address:", error);
        return null;
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        if (ipAddress) {
          const { data } = await axios.get(
            `https://ipapi.co/${ipAddress}/json/`
          );
          setCountry(data);
          setCountryFlag(data.country_code);
        }
      } catch (error) {
        console.error("Error fetching country information:", error);
      }
    })();
  }, [ipAddress]);

  return { country, countryFlag };
}
