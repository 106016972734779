import axios, { AxiosRequestConfig } from "axios";
import {
  getLocalStorageToken,
  removeLocalStorageToken,
} from "../utils/localStorageToken";
import {store } from "../redux/store";
import { logout } from "../redux/features/auth/authSlice";
import { handleGlobalLogout } from "../utils/handleGlobalLogout";

export const keyjaniApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const tokenConfig = (): AxiosRequestConfig => {
  const token = getLocalStorageToken();

  const Config: AxiosRequestConfig = {
    headers: {
      "Content-type": "application/json",
    },
  };

  if (token) {
    Config.headers!["Authorization"] = `JWT ${token}`;
  }
  return Config;
};

// Add response interceptor to handle unauthorized responses
keyjaniApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      // Clear token from local storage
      removeLocalStorageToken();
      // Dispatch logout action to clear auth state
      store.dispatch(logout());
      //clear queries and clear all state
      handleGlobalLogout();
    }
    return Promise.reject(error);
  },
);

export default axios;
