
import { Link } from "react-router-dom";
import { server_error } from "../../constants/images";
import { useErrorBoundary } from "react-error-boundary";


const InternalError = () => {
  const { resetBoundary } = useErrorBoundary();
  return (
    <div className="min-h-screen flex items-center justify-center p-4">
      <div className=" p-8 text-center max-w-md w-full">
        <div className="flex justify-center">
          <img
            draggable={false}
            src={server_error}
            alt="404 Not Found"
            className="md:h-[200px] md:w-[300px] md:scale-150 relative z-[0]"
          />
        </div>
        <div className="flex justify-center mt-10">
          <h1 className="md:text-[80px] text-[50px] font-bold">500</h1>
        </div>
        <h1 className="text-3xl font-bold text-gray-800 mb-4 relative z-[10]">
          Oops! Sorry it's not you it's us :(
        </h1>
        <p className="text-gray-600 mb-6 relative z-[10]"></p>
        <Link to="#" onClick={resetBoundary} className="bg-primary_color text-white py-2 px-4 ">
          Try again
        </Link>
      </div>
    </div>
  );
};

export default InternalError;



