import React, { useEffect, useRef, useState } from "react";
import Map, { ViewStateChangeEvent, Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  MapPinIcon,
  XMarkIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";
import useGeolocation from "../../hooks/useGeolocation";
import { BuildingType } from "../../types";
import Button from "./Button";
import { Fullscreen } from "@mui/icons-material";
import { useMapPreview } from "../../hooks/useMapPreview";
import { Modal } from "@mui/material";

import { Popover, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
type CustomMapProps = {
  buildings?: BuildingType[];
  onSelectLocation?: (lat: number, lng: number) => void;
  selectedLocation?: {
    lat: number | any;
    lng: number | any;
  } | null;
  onOpenMapPreview?: () => void;
  styles?: string;
  popoverText?: string;
};

export default function CustomMap({
  buildings = [],
  onSelectLocation,
  selectedLocation,
  styles,
  popoverText,
}: CustomMapProps) {
  const mapRef = useRef<any>(null);
  const { country } = useGeolocation();

  const [opened, { open, close }] = useDisclosure(false);
  const [newPlace, setNewPlace] = useState<{
    lat: number | any;
    long: number | any;
  } | null>(null);
  const [currentPlaceId, setCurrentPlaceId] = useState<number | null>(null);
  const [viewState, setViewState] = useState({
    longitude: country?.longitude ?? 36.8219,
    latitude: country?.latitude ?? -1.2921,
    zoom: 13,
  });
  const { isMapPreview, openMapPreview, closeMapPreview } = useMapPreview();

  const handleMarkerClick = (id: number, lat: number, long: number) => {
    setCurrentPlaceId(id);
    setViewState({ ...viewState, latitude: lat, longitude: long });
  };

  const handleAddClick = (e: any) => {
    const { lng, lat } = e.lngLat;
    setNewPlace({
      lat: lat,
      long: lng,
    });
    onSelectLocation && onSelectLocation(lat, lng);
  };

  const handleMarkerDragEnd = (e: any) => {
    const { lngLat } = e;
    const lat = lngLat.lat;
    const lng = lngLat.lng;
    onSelectLocation && onSelectLocation(lat, lng);
  };

  const handleMove = (evt: ViewStateChangeEvent) => {
    setViewState(evt.viewState);
  };

  const iconSize = Math.max(20, viewState.zoom * 2);

  useEffect(() => {
    const handleResize = () => {
      if (mapRef.current) {
        mapRef.current.resize();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`${styles} w-full h-[40vh] overflow-hidden rounded-lg drop-shadow-sm shadow-sm`}
    >
      <div className="w-full h-full">
        <Map
          ref={mapRef}
          mapboxAccessToken={process.env.REACT_APP_MAP_BOX}
          initialViewState={viewState}
          style={{ width: "100%", height: "100%" }}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          onMove={handleMove}
          onClick={handleAddClick}
        >
          <Popover
            width={200}
            position="top-end"
            withArrow
            shadow="md"
            opened={opened}
          >
            <Popover.Target>
              <div onMouseEnter={open} onMouseLeave={close}>
                <Button
                  onClick={openMapPreview}
                  buttonStyle="absolute top-3 scale-[1.3] right-3 z-[999]"
                  text={<Fullscreen className="h-8 w-8" />}
                />
              </div>
            </Popover.Target>
            <Popover.Dropdown style={{ pointerEvents: "none" }}>
              <Text size="sm">{popoverText}</Text>
            </Popover.Dropdown>
          </Popover>
          {buildings?.map((p) => {
            const latitude = Number(p?.latitude);
            const longitude = Number(p?.longitude);
            const pkid = Number(p?.pkid);

            if (!isNaN(latitude) && !isNaN(longitude) && !isNaN(pkid)) {
              return (
                <React.Fragment key={pkid}>
                  <Marker
                    latitude={latitude}
                    longitude={longitude}
                    anchor="bottom"
                  >
                    <MapPinIcon
                      style={{
                        width: `${iconSize}px`,
                        height: `${iconSize}px`,
                      }}
                      onClick={() =>
                        handleMarkerClick(pkid, latitude, longitude)
                      }
                    />
                  </Marker>
                  {pkid === currentPlaceId && (
                    <Popup
                      key={`popup-${pkid}`}
                      latitude={latitude}
                      longitude={longitude}
                      closeButton={true}
                      closeOnClick={false}
                      onClose={() => setCurrentPlaceId(null)}
                      anchor="left"
                      focusAfterOpen
                      className="m-4 px-2"
                    >
                      <div className="flex flex-col space-y-3">
                        <div className="flex items-center space-x-3">
                          <p className="text-[14px] font-medium  text-primary_color">
                            Building Name:
                          </p>
                          <h4 className="text-[12px]">{p.name}</h4>
                        </div>
                        <div className="flex items-center space-x-3">
                          <p className="text-[14px] font-medium  text-primary_color">
                            Building Address:
                          </p>
                          <p className="text-[12px]">{p.address}</p>
                        </div>
                      </div>
                    </Popup>
                  )}
                </React.Fragment>
              );
            }
            return null;
          })}
          {onSelectLocation && newPlace && (
            <Marker
              latitude={newPlace?.lat}
              longitude={newPlace?.long}
              anchor="bottom"
            >
              <MapPinIcon
                style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
              />
            </Marker>
          )}

          {selectedLocation && (
            <Marker
              latitude={selectedLocation?.lat}
              longitude={selectedLocation?.lng}
              draggable
              onDragEnd={handleMarkerDragEnd}
            >
              <MapPinIcon
                className="h-6 w-6 text-red-500"
                style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
              />
            </Marker>
          )}
        </Map>
      </div>

      <Modal
        open={isMapPreview}
        onClose={closeMapPreview}
        aria-labelledby="map-preview-modal"
        aria-describedby="fullscreen-map-preview"
        className="relative"
      >
        <div className="my-12 mx-auto w-[90vw] h-[85vh] bg-white rounded-lg p-0">
          <div
            onClick={closeMapPreview}
            className="absolute top-5 right-7 h-8 w-8 rounded-full bg-[#F1F5F9] flex items-center justify-center cursor-pointer"
          >
            <XMarkIcon className="h-5 w-5 text-gray-500" />
          </div>
          <Map
            mapboxAccessToken={process.env.REACT_APP_MAP_BOX}
            initialViewState={viewState}
            style={{ width: "100%", height: "100%", borderRadius: "10px" }}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            onMove={handleMove}
            onClick={handleAddClick}
          >
            {buildings?.map((p) => {
              const latitude = Number(p?.latitude);
              const longitude = Number(p?.longitude);
              const pkid = Number(p?.pkid);

              if (!isNaN(latitude) && !isNaN(longitude) && !isNaN(pkid)) {
                return (
                  <React.Fragment key={pkid}>
                    <Marker
                      latitude={latitude}
                      longitude={longitude}
                      anchor="bottom"
                    >
                      <MapPinIcon
                        style={{
                          width: `${iconSize}px`,
                          height: `${iconSize}px`,
                        }}
                        onClick={() =>
                          handleMarkerClick(pkid, latitude, longitude)
                        }
                      />
                    </Marker>
                    {pkid === currentPlaceId && (
                      <Popup
                        key={`popup-${pkid}`}
                        latitude={latitude}
                        longitude={longitude}
                        closeButton={true}
                        closeOnClick={false}
                        closeOnMove
                        onClose={() => setCurrentPlaceId(null)}
                        anchor="left"
                        className="m-4"
                      >
                        <div className="flex flex-col space-y-3">
                          <div className="flex items-center space-x-3">
                            <p className="text-[14px] font-medium text-primary_color">
                              Building Name:
                            </p>
                            <h4 className="text-[12px]">{p.name}</h4>
                          </div>
                          <div className="flex items-center space-x-3">
                            <p className="text-[14px] font-medium text-primary_color">
                              Building Address:
                            </p>
                            <p className="text-[12px]">{p.address}</p>
                          </div>
                        </div>
                      </Popup>
                    )}
                  </React.Fragment>
                );
              }
              return null;
            })}
            {onSelectLocation && newPlace && (
              <Marker
                latitude={newPlace?.lat}
                longitude={newPlace?.long}
                anchor="bottom"
              >
                <MapPinIcon
                  style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
                />
              </Marker>
            )}

            {selectedLocation && (
              <Marker
                latitude={selectedLocation?.lat}
                longitude={selectedLocation?.lng}
                draggable
                onDragEnd={handleMarkerDragEnd}
              >
                <MapPinIcon
                  className="h-6 w-6 text-red-500"
                  style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
                />
              </Marker>
            )}
          </Map>
        </div>
      </Modal>
    </div>
  );
}
