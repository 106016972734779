import React from "react";
import { Modal } from "@mantine/core";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Button } from "..";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { keyjaniApi, tokenConfig } from "../../api";
import { ResponseStatus, Screens } from "../../types/enums";
import { useAlert } from "../../hooks/useAlert";
import { Spinner } from "../../components";

interface DeleteAccountModalProps {
  open: boolean;
  onClose: () => void;
}

export default function DeleteAccountModal({
  open,
  onClose,
}: DeleteAccountModalProps) {
  const { userInfo, signOut } = useAuth();
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const mutation = useMutation({
    mutationFn: () =>
      keyjaniApi.delete(`/auth/profile/${userInfo?.id}/`, tokenConfig()),
    onSuccess: () => {
      showAlert({
        message: "Account deleted successfully",
        type: ResponseStatus.SUCCESS,
        component: Screens.dashboard,
      });
      signOut();
      navigate("/login");
      onClose();
    },
    onError: (error: any) => {
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.detail || "Failed to delete account";
        showAlert({
          error: errorMessage,
          type: ResponseStatus.FAILED,
          component: Screens.dashboard,
        });
      }
      onClose();
    },
  });

  return (
    <Modal
      opened={open}
      onClose={onClose}
      title=""
      centered
      classNames={{
        content: "p-0 rounded-2xl",
        header: "p-0",
        close: "hidden",
      }}
    >
      <div className="p-2 flex flex-col space-y-6">
        <div className="flex items-center space-x-4">
          <div className="p-2 rounded-full bg-red-100 flex items-center justify-center">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-500" />
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-900">
              Delete Account
            </h3>
            <p className="text-sm text-gray-500">
              Are you sure you want to delete your account? This action cannot
              be undone.
            </p>
          </div>
        </div>

        <div className="flex items-center justify-end space-x-4">
          <Button
            text="Cancel"
            onClick={onClose}
            buttonStyle="px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg"
          />
          <Button
            text={
              mutation.isPending ? (
                <Spinner styles="h-4 w-4 border-2" />
              ) : (
                "Delete Account"
              )
            }
            onClick={() => mutation.mutate()}
            buttonStyle="px-4 py-2 text-white bg-red-500 hover:bg-red-600 rounded-lg"
          />
        </div>
      </div>
    </Modal>
  );
}

