import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

export default function Modal({
  open,
  title,
  body,
  actions,
  onClose,
  style,
  className,
  fullwidth,
  maxWidth
}: {
  open: boolean;
  title: React.ReactNode;
  body: React.ReactNode;
  actions?: React.ReactNode;
  onClose: () => void;
  style?: Object;
  className?: string,
  fullwidth?: boolean,
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false

}) {
  const [showModal, setShowModal] = useState<boolean>(open);

  useEffect(() => {
    setShowModal(open);
  }, [open]);

  const handleClose = useCallback(() => {
    setShowModal(false);
    setTimeout(() => {
      onClose();
    }, 300);
  }, [onClose]);

  return (
    <React.Fragment>
      <Dialog style={style}
        className={className}
        open={showModal}
        fullWidth={fullwidth}
        maxWidth={maxWidth ?? "md"}
        onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{body}</DialogContent>
        <DialogActions className="m-4" >{actions}</DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
