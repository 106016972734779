export const tabStatus = [
  { id: 0, title: "Members" },
  { id: 1, title: "Invitations" },
];

export const columns = [
  { id: 1, label: "Name" },

  {
    id: 2,
    label: "Member Role",
  },
  {
    id: 3,
    label: "Status",
  },
  {
    id: 4,
    label: "Actions",
  },
];

export const memberColumns = [
  { id: 1, label: "Name" },

  {
    id: 2,
    label: "Member Role",
  },
  {
    id: 3,
    label: "Actions",
  },
];

export const rentColumns = [
  { id: 1, label: "Reference Number" },

  {
    id: 2,
    label: "Rent Amount",
  },
  {
    id: 3,
    label: "Date Paid",
  },
  {
    id: 4,
    label: "Rent Type",
  },
  {
    id: 5,
    label: "Rent Month",
  },
  {
    id: 6,
    label: "Actions",
  },
];

export const utilityColumns = [
  { id: 1, label: "Reference Number" },

  {
    id: 2,
    label: "Amount",
  },
  {
    id: 3,
    label: "Payment date",
  },
  {
    id: 4,
    label: "Utility type",
  },
  {
    id: 5,
    label: "Actions",
  },
];

export const rentalUnitColumns = [
  { id: 1, label: "Apartment Number" },
  {
    id: 2,
    label: "Rent Amount",
  },
  {
    id: 3,
    label: "Rent Due Day",
  },
  {
    id: 4,
    label: "Status",
  },
  {
    id: 5,
    label: "Actions",
  },
];

export const bnbUnitColumns = [
  {
    id: 1,
    label: "Apartment Number"
  },
  {
    id: 2,
    label: "Day Rate",
  },
  {
    id: 3,
    label: "Status",
  },
  {
    id: 4,
    label: "Actions",
  },
];

export const buildingColumns = [
  { id: 1, label: "Name" },
  {
    id: 2,
    label: "Address",
  },
  {
    id: 3,
    label: "Total floors",
  },
  {
    id: 4,
    label: "Total Units",
  },
  {
    id: 5,
    label: "Total Vacancies",
  },
  {
    id: 6,
    label: "Occupancy rate",
  },
  {
    id: 7,
    label: "Actions",
  },
];

export const tenantColumns = [
  { id: 1, label: "Name" },

  {
    id: 2,
    label: "Email",
  },
  {
    id: 3,
    label: "Phone",
  },
  {
    id: 4,
    label: "Actions",
  },
];

export const serviceColumns = [
  { id: 1, label: "Service Type" },
  {
    id: 2,
    label: "Cost",
  },
  {
    id: 3,
    label: "Due Date",
  },
  {
    id: 4,
    label: "Status",
  },
  {
    id: 5,
    label: "Priority",
  },
  {
    id: 6,
    label: "More",
  },
  {
    id: 7,
    label: "Actions",
  },
];
