import { Link } from "react-router-dom";
import { MenuSelectBuilding } from "..";
import { WorkspaceType } from "../../types";

const Breadcrumb = ({
  pageName,
  isMenu,
  subtitle,
}: {
  pageName: string;
  subtitle?: string;
  workspace?: WorkspaceType;
  isMenu?: boolean;
}) => {
  return (
    <>
      <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
        <div className="flex flex-col space-y-1">
          <div className="flex item-center justify-between space-x-3">
            <h2 className="text-[30px] font-semibold text-black dark:text-white">
              {pageName}
            </h2>
            {isMenu && <MenuSelectBuilding />}
          </div>
        </div>

        {
          <nav>
            <ol className="flex items-center gap-2">
              <li className="text-sm">
                <Link to="/dashboard">Dashboard /</Link>
              </li>
              <li className="font-medium text-sm">{pageName}</li>
            </ol>
          </nav>
        }
      </div>
    </>
  );
};

export default Breadcrumb;
