import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import appReducer from "../redux/features/app/appSlice";
import authReducer from "../redux/features/auth/authSlice";
import workSpaceReducer from "../redux/features/workspace/workSpaceSlice";
import buildingReducer from "../redux/features/building/buildingSlice";
import unitReducer from "../redux/features/units/unitSlice";
import serviceReducer from "../redux/features/services/serviceSlice";
import imageReducer from "../redux/features/image/imageSlice";
import propertyReducer from "../redux/features/properties/propertySlice";
import alertReducer from "../redux/features/alert/alertSlice";
import tenantReducer from "../redux/features/tenant/tenantSlice";

// Only persist auth data
//decided to only persist auth data to prevent memory leaks and unnecessary data persistence
const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ['token', 'userInfo']
};

const rootReducer = combineReducers({
  app: appReducer,
  image: imageReducer,
  alert: alertReducer,
  tenant: tenantReducer,
  property: propertyReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  workspace: workSpaceReducer,
  building: buildingReducer,
  unit: unitReducer,
  service: serviceReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
