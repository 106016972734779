export enum ButtonType {
  button = "button",
  submit = "submit",
  reset = "reset",
}

export enum tabOptions {
  MEMBERS = "Members",
  INVITATIONS = "Invitations",
}

export enum SOCIAL_AUTH {
  GOOGLE = "google",
  FACEBOOK = "facebook",
}

export enum ResponseStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  DENIED = "DENIED",
  INFO = "INFO",
}

export enum ServicePriority {
  High = 1,
  Medium = 2,
  Low = 3,
  Critical = 4,
}

export const ServicePriorityDisplay = {
  [ServicePriority.High]: "High",
  [ServicePriority.Medium]: "Medium",
  [ServicePriority.Low]: "Low",
  [ServicePriority.Critical]: "Critical",
};

export enum Screens {
  login = "login",
  register = "register",
  reset_password = "reset_password",
  forgot_password = "forgot_password",
  email_verification = "email_verification",
  dashboard = "",
  workspace = "workspace",
  units = "units",
  units_details = "units_details",
  buildings = "buildings",
  building_detail = "building_detail",
  services = "services",
  services_details = "services_details",
  properties = "properties",
  accept_invitation = "accept_invitation",
  tenants = "tenants",
}

export enum MobileTabs {
  Appointment = 1,
  Posts,
  Recovered,
  Security,
}

export enum UtilityType {
  Garbage = "Garbage",
  Water = "Water",
}

export enum RentType {
  Deposit = "Deposit",
  Rent = "Rent",
  Lease = "Lease",
}

export enum Months {
  January = "January",
  February = "February",
  March = "March",
  April = "April",
  May = "May",
  June = "June",
  July = "July",
  August = "August",
  September = "September",
  October = "October",
  November = "November",
  December = "December",
}

export enum Statuses {
  pending = 1,
  progress = 2,
  done = 3,
}

export const StatusesDisplay = {
  [Statuses.pending]: "Pending",
  [Statuses.progress]: "In Progress",
  [Statuses.done]: "Done",
};
