
import { store } from "../redux/store";
import { logout } from "../redux/features/auth/authSlice";
import { removeLocalStorageToken } from "./localStorageToken";
import { persistor } from "../redux/store";
import { queryClient } from "..";


export const handleGlobalLogout = () => {
  console.log("Logging out user...");
  // Remove token
  removeLocalStorageToken();

  console.log("Removed user token...");
  // Dispatch Redux logout
  store.dispatch(logout());

  console.log("Dispatched logout reducer...");
  // Clear cached queries
  queryClient.clear()

  console.log("Cleared all cached queries...");


  // Clear persist storage
  persistor.flush().then(() => {
    persistor.purge();
    console.log("Purged all persisted data...");

    // Reload page for a full reset
    window.location.replace("/login");
    //window.location.reload();  // This forces full state reset
  });
  console.log("user logged out...");
};
