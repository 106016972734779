import React, { useState } from "react";
import {
  UserCircleIcon,
  EnvelopeIcon,
  PhoneIcon,
  PencilSquareIcon,
  MapPinIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  CheckBadgeIcon,
  ExclamationTriangleIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Button } from "../../components";
import { Card, LoadingOverlay, Switch, Tabs } from "@mantine/core";
import useCustomQuery from "../../hooks/useCustomQuery";
import useAuth from "../../hooks/useAuth";
import DeleteAccountModal from "../../components/modals/DeleteAccount";
import Modal from "./Modal";
import { BellIcon, MoonIcon, SunIcon } from "@heroicons/react/24/solid";
import { useMantineColorScheme } from "@mantine/core";

export default function ProfileModal({
  isOpen,
  Close
}: {
  isOpen: boolean;
  Close: () => void;
  style?: Object;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { setColorScheme, colorScheme } = useMantineColorScheme();

  const { userInfo } = useAuth();
  const { data: userData, isLoading: isLoadingUser } = useCustomQuery(
    "user",
    "/auth/profile/me/",
    true,
  );

  const formatDate = (dateString?: Date | null | any) => {
    if (!dateString) return undefined;
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const InfoRow = ({
    icon,
    label,
    value,
  }: {
    icon: React.ReactNode;
    label: string;
    value?: string | number | boolean | null;
  }) => (
    <div className="flex items-center space-x-4 p-4 hover:bg-gray-50 rounded-lg transition-all duration-200">
      <div className="text-gray-500">{icon}</div>
      <div>
        <p className="text-sm text-gray-500">{label}</p>
        <p className="text-base font-medium">{value || "Not set"}</p>
      </div>
    </div>
  );



  const headContent = (
    <div className="flex flex-row items-center justify-between px-4 pt-4">
      <div className="flex items-center space-x-4">
        <h2 className="text-2xl font-bold text-primary_color">
          Profile Settings
        </h2>
      </div>
      <div
        className="hover:bg-gray-200 p-2 rounded-full transition-colors cursor-pointer"
        onClick={Close}
      >
        <XMarkIcon className="w-6 h-6 text-gray-600" />
      </div>
    </div>
  );

  const bodyContent = (
    <main className="md:p-4 p-0">
      <Tabs
        defaultValue="profile"
        orientation="horizontal"
        className="w-full"
      >
        <Tabs.List className="bg-none p-2 md:min-w-[200px] mb-4 sticky top-0">
          <Tabs.Tab
            value="profile"
            className="!px-4 !py-3 rounded-md hover:bg-primary_color/10 data-[active=true]:bg-primary_color data-[active=true]:text-white"
          >
            Profile
          </Tabs.Tab>
          <Tabs.Tab
            value="settings"
            className="!px-4 !py-3 rounded-md hover:bg-primary_color/10 data-[active=true]:bg-primary_color data-[active=true]:text-white"
          >
            Settings
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="profile">
          <div className="flex flex-col space-y-6 ">
            <div className="relative border border-stroke rounded-xl bg-white shadow-sm overflow-hidden">
              <LoadingOverlay visible={isLoading || isLoadingUser} />

              <div className="relative h-32 bg-gradient-to-r from-primary_color to-primary_color/80">
                <div className="absolute -bottom-16 left-8">
                  <div className="relative">
                    <div className="h-32 w-32 rounded-xl bg-white p-2 shadow-lg">
                      {userInfo?.profile ? (
                        <img
                          src={userInfo.profile}
                          alt="Profile"
                          className="h-full w-full rounded-lg object-cover"
                        />
                      ) : (
                        <div className="h-full w-full rounded-lg bg-gray-100 flex items-center justify-center">
                          <UserCircleIcon className="h-20 w-20 text-gray-400" />
                        </div>
                      )}
                    </div>
                    {isEditing && (
                      <button className="absolute bottom-2 right-2 bg-primary_color p-2 rounded-lg text-white hover:bg-primary_color/90 shadow-lg">
                        <PencilSquareIcon className="h-4 w-4" />
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-20 p-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <InfoRow
                    icon={<UserCircleIcon className="h-5 w-5" />}
                    label="Full Name"
                    value={userInfo?.full_name}
                  />
                  <InfoRow
                    icon={<EnvelopeIcon className="h-5 w-5" />}
                    label="Email"
                    value={userInfo?.email}
                  />
                  <InfoRow
                    icon={<PhoneIcon className="h-5 w-5" />}
                    label="Phone"
                    value={userInfo?.phone_number?.toString()}
                  />
                  <InfoRow
                    icon={<MapPinIcon className="h-5 w-5" />}
                    label="Location"
                    value={userInfo?.location}
                  />
                  <InfoRow
                    icon={<GlobeAltIcon className="h-5 w-5" />}
                    label="Country"
                    value={userInfo?.country || "Kenya"}
                  />
                  {/*<InfoRow
                icon={<CalendarIcon className="h-5 w-5" />}
                label="Joined"
                value={formatDate(userInfo?.createdAt)}
              />*/}
                  <InfoRow
                    icon={<CheckBadgeIcon className="h-5 w-5" />}
                    label="verification"
                    value={
                      userInfo?.email_verified
                        ? "Email verified"
                        : "Email not verified"
                    }
                  />
                </div>

                <div className="mt-8 p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-center space-x-2 mb-2">
                    <InformationCircleIcon className="h-5 w-5 text-gray-500" />
                    <h3 className="text-lg font-semibold">About</h3>
                  </div>
                  <p className="text-gray-600 whitespace-pre-line">
                    {userInfo?.bio || "No bio added yet."}
                  </p>
                </div>
              </div>
            </div>


            <div>
              <Button
              />

              <DeleteAccountModal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
              />
            </div>
          </div>
          <div className="border border-stroke rounded-xl bg-white shadow-sm flex flex-col space-y-6 p-6 md:p-8">
            <div className="flex items-center gap-3">
              <ExclamationTriangleIcon className="h-5 w-5 text-red-500" />
              <h1 className="text-2xl font-bold text-red-500">Danger Zone</h1>
            </div>

            <h1 >Delete Account</h1>
            <Button
              text="Delete Account"
              icon={<TrashIcon className="h-4 w-4 text-white" />}
              onClick={() => setDeleteModalOpen(true)}
              buttonStyle=" px-6 py-2.5 text-white cursor-pointer rounded-lg bg-red-500 flex items-center justify-center duration-300 ease-in-out hover:bg-red-500/90 hover:shadow-md"
            />
          </div>        </Tabs.Panel>

        <Tabs.Panel value="settings">
          <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">Settings</h2>
            <div className="space-y-4">
              {/* Dark Mode Toggle */}
              <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center gap-3">
                  <MoonIcon className="w-6 h-6 text-gray-600" />
                  <span className="text-base font-medium text-gray-700">Dark Mode</span>
                </div>
                <Switch
                  size="md"
                  color="blue"
                  checked={colorScheme === "dark"}
                />
              </div>

              {/*onChange={() => setColorScheme(colorScheme === "light" ? "dark" : "light")}*/}

              {/* Notifications Toggle */}
              <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center gap-3">
                  <BellIcon className="w-6 h-6 text-gray-600" />
                  <span className="text-base font-medium text-gray-700">Notifications</span>
                </div>
                <Switch size="md" color="blue" />
              </div>
            </div>
          </div>
        </Tabs.Panel>
      </Tabs>
    </main>
  );

  return (
    <Modal
      open={isOpen}
      onClose={Close}
      body={bodyContent}
      title={headContent}
      maxWidth="md"
      fullwidth
      style={{
        zIndex: 99,
        width: "100%"
      }}
    />
  );
}
